<!-- 个人档案 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="persCenter-content">
      <div class="first">
        <span class="ziliao">个人资料</span>
        <ul>
          <li>
            <span class="tit">用户头像</span>
            <span class="userimg" v-if="!comDetails.icon"
            ><i class="el-icon-user iconPeople"></i
            ></span>
            <img v-if="comDetails.icon" :src="comDetails.icon" class="userimg">
            <div class="upload-userIcon">
              <el-button style="margin: 0" type="text"
                >修改头像</el-button
              >
              <input class="common-upload-input" @input="chooseFile" type="file" />
            </div>
          </li>
          <li>
            <span class="tit">用户名称</span
            ><span class="name">{{ comDetails.nick_name || "--" }}</span>
          </li>
          <li>
            <span class="tit">手机号</span
            ><span class="name">{{ comDetails.account || "--" }}</span>
          </li>
          <li>
            <span class="tit">微信号</span>
            <span v-if="!editFlag" class="name">{{
              comDetails.wx_id || "--"
            }}</span>
            <el-input
              v-else
              class="common-screen-input iptW"
              v-model="params.wxId"
              placeholder="请输入内容"
            ></el-input>
          </li>
          <li>
            <span class="tit">角色</span>
            <span class="name">{{ rolesName || "--" }}</span>
            <!-- <el-select
              v-else
              class="common-screen-input iptW"
              v-model="params.openId"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in selectRole"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </li>
          <li>
            <span class="tit">部门</span
            ><span v-if="!editFlag" class="name">{{
              comDetails.user_depart_name || "--"
            }}</span>
            <a-cascader
              v-else
              class="common-screen-input iptW"
              :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'child'
              }"
              :allowClear="false"
              v-model="params.userDepartId"
              :options="selectDepart"
              placeholder="请选择"
              change-on-select
              @change="onChange"
            />
            <!-- <el-cascader
              v-else
              clearable
              :props="{
                label: 'name',
                children: 'child',
                value: 'id',
                emitPath: false
              }"
              class="common-screen-input iptW"
              v-model="params.userDepartId"
              :options="selectDepart"
            >
            </el-cascader> -->
          </li>
          <li>
            <span class="tit">性别</span
            >
            <span v-if="!editFlag" class="name">{{
              (comDetails&&comDetails.sex&&comDetails.sex != 'undefined') ? (comDetails.sex == 0 ? "女" : "男") : "--"
            }}</span>
            <el-radio-group v-else v-model="params.sex">
              <el-radio :label="'1'">男</el-radio>
              <el-radio :label="'0'">女</el-radio>
            </el-radio-group>
          </li>
          <li>
            <span class="tit">生日</span
            ><span v-if="!editFlag" class="name">{{
              comDetails.birth_day || "--"
            }}</span>
            <el-date-picker
              v-else
              v-model="params.birthDay"
              type="date"
              class="common-screen-input iptW"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </li>
          <li style="display: flex;align-items: center;">
            <span class="tit">地址</span>
            <span v-if="!editFlag" class="name"
              >{{ addName || "--" }} {{ comDetails.address || "--" }}</span
            >
            <!-- 编辑显示 -->
            <div v-else style="display: flex;align-items: center;">
              <el-cascader
                :props="props"
                v-model="params.addressId"
                class="common-screen-input"
                clearable
                placeholder="省市区"
              ></el-cascader>
              <el-input
                v-model="params.address"
                class="common-screen-input_55"
                placeholder="请输入具体地址"
              >
              </el-input>
            </div>
          </li>
          <li>
            <span class="tit">银行卡号</span
            ><span v-if="!editFlag" class="name">{{
              comDetails.bank_card || "--"
            }}</span>
            <el-input
              v-else
              class="common-screen-input iptW"
              v-model="params.bankCard"
              placeholder="请输入内容"
            ></el-input>
          </li>
          <li v-if="comDetails.questionnaire">
            <span class="tit">问卷调查</span>
            <span class="name">
              员工问卷调查内容
              <el-button
                @click="
                  () => {
                    goQa();
                  }
                "
                type="text"
                class="see"
                >查看</el-button
              >
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer" style="text-align: left;">
      <el-button
        v-if="!editFlag"
        @click="
          () => {
            editCom();
          }
        "
        class="edit"
        style="background-color: #1890ff;color: #fff;"
        >修改</el-button
      >
      <el-button
        v-else
        @click="
          () => {
            updateForm();
          }
        "
        class="edit"
        style="background-color: #1890ff;color: #fff;"
        >确定</el-button
      >
      <el-button
        v-if="editFlag"
        @click="
          () => {
            editFlag = false
          }
        "
        class="edit"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Cascader } from "ant-design-vue";
import { Config } from "../../utils/index.js";
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { companyInfo, personUpdate } from "../../service/login.js";
import { userData } from "../../service/common.js";
import {
  sysAreaDetail,
  sysArea,
  commonAllRole,
  commonDepartAll
} from "../../service/common.js";
import { upload } from "../../service/upload.js";

export default {
  name: "comCenter",
  components: { Breadcrumb,aCascader: Cascader },
  data() {
    return {
      Config,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "个人档案", isLink: false }
      ],
      // rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => {return item.id != 0;}), // 所属公司下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).company_id,
      userInfo: this.$sto.get(Config.constants.userInfo),
      address: "",
      detailAddress: "",
      comDetails: {}, //公司详情
      addName: "",
      editFlag: false,
      loading: false,
      flag: false,
      params: {}, //修改新参数
      params2: {
        //部门&&角色 参数
        pageNum: 1,
        pageSize: 10
      },
      props: {
        //地区
        lazy: true,
        lazyLoad: async (node, resolve) => {
          this.loading = true;
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          this.loading = false;
          resolve(nodes);
        }
      },
      rolesName: "",
      companyId: "", //公司ID
      selectRole: [], // 角色下拉
      selectDepart: [] // 部门下拉
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.rolesCompany) {
      this.companyId = this.rolesCompany;
      this.commonDepartAll(this.rolesCompany);
    }
    this.commonAllRole();
    this.getDetails();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getDetails() {
      this.loading = true;
      this.fileList = [];
      const { data } = await companyInfo();
      let addressId = data.address_id;
      // 地区
      if (data && addressId) {
        let id = addressId.split(",")[addressId.split(",").length - 1];
        const { data: res } = await sysAreaDetail({ id });
        this.addName = res;
      }
      //  角色
      if (data && data.roleId) {
        let resData = (await commonAllRole({ companyId: this.companyId })).data;
        resData.forEach((item) => {
          if (item.id == data.roleId) {
            this.rolesName = item.name;
          }
        });
      }
      this.comDetails = data;
      // 性别
      this.comDetails.sex = data.sex + "";
      this.loading = false;
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    async commonAllRole() {
      // 角色下拉
      let resData = (await commonAllRole({ companyId: this.companyId })).data;
      this.selectRole = resData;
    },
    editCom() {
      this.editFlag = true;
      this.params = {
        wxId: this.comDetails.wx_id,
        openId: Number(this.comDetails.roleId),
        // userDepartId: [this.comDetails.user_depart_id],
        // sex: Number(this.comDetails.sex) || 1,
        birthDay: this.comDetails.birth_day,
        address: this.comDetails.address,
        bankCard: this.comDetails.bank_card,
        sex: ""
      };
      this.findParent(this.comDetails.user_depart_id,this.selectDepart);//部门
      if(this.comDetails.sex&&this.comDetails.sex != 'undefined'){
        this.params.sex = this.comDetails.sex
      }else{
        this.params.sex = '1'
      }
      if (this.comDetails && this.comDetails.address_id) {
        this.params.addressId = this.comDetails.address_id.split(",");
      }
    },
    // 递归父id
    findParent(childrenId, arr, path) {
      if (path === undefined) {
        path = []
      }
      for (let i = 0; i < arr.length; i++) {
        let tmpPath = path.concat();
        tmpPath.push(arr[i].id);
        if (childrenId == arr[i].id) {
          return this.params.userDepartId = tmpPath
        }
        if (arr[i].child) {
          let findResult = this.findParent(childrenId, arr[i].child, tmpPath);
          if (findResult) {
            return this.params.userDepartId = findResult
          }
        }
      }
    },
    // 修改
    async updateForm() {
      this.comDetails.companyId = this.comDetails.company_id;
      this.comDetails.companyName = this.comDetails.company_name;
      this.comDetails.createId = this.comDetails.create_id;
      this.comDetails.createTime = this.comDetails.create_time;
      this.comDetails.isDeleted = this.comDetails.is_deleted;
      this.comDetails.joinTime = this.comDetails.join_time;
      this.comDetails.nickName = this.comDetails.nick_name;
      this.comDetails.passWord = this.comDetails.pass_word;
      this.comDetails.updateId = this.comDetails.update_id;
      this.comDetails.updateTime = this.comDetails.update_time;
      this.comDetails.userDepartId = this.comDetails.user_depart_id;
      this.comDetails.userDepartName = this.comDetails.user_depart_name;
      this.comDetails.haveCompany = this.comDetails.have_company;
      let params = { ...this.comDetails };
      delete params.update_id;
      delete params.update_time;
      delete params.address_id;
      delete params.company_id;
      delete params.company_name;
      delete params.create_id;
      delete params.create_time;
      delete params.is_deleted;
      delete params.join_time;
      delete params.nick_name;
      delete params.pass_word;
      delete params.user_depart_id;
      delete params.user_depart_name;
      delete params.address;
      delete params.wx_id;
      delete params.have_company;

      if (this.params && this.params.addressId) {
        params.addressId = this.params.addressId.join(",");
      }
      if(this.params && this.params.userDepartId && this.params.userDepartId.length){
        params.userDepartId = Number(this.params.userDepartId[this.params.userDepartId.length - 1]);
      }
      params.wxId = this.params.wxId;
      params.openId = this.params.openId;
      params.sex = this.params.sex;
      params.birthDay = this.params.birthDay;
      params.address = this.params.address;
      params.bankCard = this.params.bankCard;
      const res = await personUpdate(params);

      this.editFlag = false; //关闭修改
      // 调info接口刷新角色
      let { data } = await userData();
      if (data) {
        this.$vuex.commit("getUserInfo", data);
      }
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
      this.getDetails();
    },
    goQa() {
      //  id--用户ID flag: true---查看
      this.$router.push({
        path: "/pers-ques",
        query: { id: this.comDetails.id, flag: true }
      });
    },
    onChange(value) {
      this.$set(this.params,'userDepartId',value)
      this.$forceUpdate()
    },
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
     if (file.size / 1024 > 1024 * 50) {
          e.target.value = "";
          return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.imgType.includes(format)) {
          e.target.value = "";
          return this.$message.error(`只能上传 ${Config.fileMessage.imgType} 格式的文件`)
      }
      let res = (await upload({ file: file })).data;
      await personUpdate({ id: this.comDetails.id,icon: res.filename });
      e.target.value = "";
      this.userInfo.icon = res.filename;
      this.$vuex.commit('getUserIcon', res.filename)
      this.$sto.set(Config.constants.userInfo, this.userInfo);
      this.getDetails();
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px;
  .breadcrumb-container {
    margin-left: 0;
  }
  .persCenter-content {
    .first {
      margin-bottom: 32px;
      // height: 314px;
      text-align: left;
      border-radius: 8px;
      border: 1px solid rgba(238, 238, 238, 100);
      .ziliao {
        padding: 16px;
        display: block;
        border-bottom: 1px solid rgba(238, 238, 238, 100);
      }
      ul {
        padding: 16px;
        li {
          &:nth-child(-n + 10) {
            margin-bottom: 16px;
          }
          /deep/.el-button--text{
              padding: 0;
          }
        }
      }
    }
    .footer {
      /deep/.el-button,
      /deep/.el-button--default,
      /deep/.edit {
        width: 88px !important;
        height: 32px !important;
        background: #1890ff !important;
      }
    }
    .tit {
      display: inline-block;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      width: 100px;
    }
    .upload-userIcon{
      display: inline-block;
      position: relative;
    }
    .userimg{
      display: inline-block;
      width: 48px;
      height: 48px;
      margin-right: 32px;
      border-radius: 50%;
      vertical-align: middle;
      .iconPeople {
        width: 100%;
        height: 100%;
        color: #fff;
        background: #87d068;
        border-radius: 50%;
        font-size: 48px;
        line-height: 48px;
      }
    }
    .name {
      display: inline-block;
      color: #999;
      .see{
        margin-left: 32px;
      }
    }
    .orderFileBtn {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #666;
      background: #fff;
      text-align: center;
      font-size: 14px;
      position: relative;
      border-radius: 4px;
      display: inline-block;
      .orderFile-input {
        width: 100px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
      }
    }
  }
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    margin-bottom: 16px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
  // .fileList-item{
  //   display: inline-block;
  // }
  .add-ipt {
    height: 32px;
  }
  /deep/ .el-input {
    height: 32px !important;
    line-height: 30px;
  }
  .common-screen-input {
    margin-left: 0 !important;
    // width: 281px !important;
  }
  .iptW {
    width: 480px !important;
  }
  .common-screen-input_55 {
    margin-left: 12px;
    width: 217px !important;
  }
}
</style>
